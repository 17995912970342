import React, { useRef } from "react";
import { motion, useScroll } from "framer-motion";

function Projet({ projet }) {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["0 1", "1.33 1.2"]
    });

    return (
        <motion.div
            ref={ref}
            style={{ scale: scrollYProgress }}
            whileTap={{ rotate: projet.id % 2 === 0 ? -1.1 : 1.1 }}
            whileHover={{ rotate: projet.id % 2 === 0 ? -1.1 : 1.1 }}
            initial={{ rotate: 0, scale: 1 }}
            key={projet.id}
            className={`relative my-10 text-white rounded-2xl p-5 ${projet.id % 2 === 0 ? "self-end" : "self-start"}`}
        >
            <div
                className="py-20 px-10 rounded-3xl h-[650px] w-[350px] lg:h-[450px] lg:w-[750px] shadow-xl shadow-gray-400 dark:shadow-neutral-900"
                style={{
                    backgroundImage: `url(${projet.img})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <div className="text-3xl mb-5">{'> ' + projet.nom}</div>
                <div className="text-lg mb-5">{'[ ' + projet.techno + ' ]'}</div>
                <div className="">{projet.description}</div>
            </div>
        </motion.div>
    );
}

export default function Projets() {
    const projets = [
        {
            id: 1,
            nom: "Miroir Intelligent",
            techno: "ReactNative, Electron, TailwindCSS, Framer Motion",
            description: "Création d'un miroir intelligent, avec une application mobile et desktop. Utilisation de base de données et d'API REST pour stocker les informations des utilisateurs." +
                "Le miroir affiche l'heure, la date et est paramétrable pour afficher des informations supplémentaires.",
            img: "/images/background.webp"
        },
        {
            id: 2,
            nom: "Kidoikoi",
            techno: "PHP, HTML, CSS, Laravel",
            description: "Création d'un site web de partage de dépenses entre amis, en utilisant le framework Laravel. Travail effectué en équipe, en collaboration avec un client." +
                "Nous avons utilisé Jira pour la gestion de projet, et avons suivi la méthode Agile.",
            img: "/images/background.webp"
        },
        {
            id: 3,
            nom: "Les Légendaires",
            techno: "React, TailwindCSS, REST API",
            description: "Site web utilisant l'API de Supercell pour afficher les stats des joueurs de Clash of Clans.",
            img: "/images/background.webp"
        },
        {
            id: 4,
            nom: "Portfolio",
            techno: "HTML, CSS, ASP.Net, React",
            description: "Création de mon portfolio, au départ en HTML/CSS, ASP.Net par la suite, puis en React.",
            img: "/images/background.webp"
        }
    ];

    return (
        <div className="projets" id="projets">
            <div className="text-center text-4xl my-10 mt-20">Projets</div>
            <div className="flex flex-col mx-auto">
                {projets.map((projet) => (
                    <Projet key={projet.id} projet={projet} />
                ))}
            </div>
        </div>
    );
}
