import { AnimatePresence } from 'framer-motion';
import { Squeeze as Hamburger } from 'hamburger-react';
import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import DarkMode from './DarkMode';

function NavigationBar() {
  const [isOpen, setOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  // Close menu on scroll or clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    const handleScroll = () => {
      setOpen(false);
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [menuRef, buttonRef]);

  return (
    <nav
      className={`${isScrolled ? 'bg-neutral/30 backdrop-blur-lg shadow-md border border-neutral-200/10 dark:border-neutral-700' : 'bg-transparent'
        } fixed top-4 left-1/2 transform -translate-x-1/2 w-11/12 max-w-6xl rounded-full flex justify-between items-center px-6 transition-all duration-300 z-50`}
    >
      <div className='m-10 text-4xl'>
        <a href='/'>
          Td.
        </a>
      </div>

      {/* Desktop Navigation */}
      <div className="hidden md:flex items-center flex-nowrap text-2xl ">
        <a className="me-10 hover:text-slate-500" href="/">Accueil</a>
        <a className="me-10 hover:text-slate-500" href="#projets">Projets</a>
        <a className="me-10 hover:text-slate-500" href="#contact">Contact</a>
        <div className="ml-auto">
          <DarkMode />
        </div>
      </div>

      {/* Mobile Menu Button */}
      <div className='m-10 md:hidden z-30' ref={buttonRef}>
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </div>

      {/* Mobile Navigation Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            ref={menuRef}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
            transition={{ duration: 0.5 }}
            id='menu'
            className={`${isScrolled ? 'bg-neutral/30  backdrop-blur-lg shadow-md border border-neutral-200/10 dark:border-neutral-700' : 'bg-slate-300 bg-opacity-50'
              } flex flex-col md:hidden rounded-3xl gap-y-4 text-3xl z-50 fixed left-0 right-0 top-[9.5rem] p-10 shadow-lg dark:bg-neutral-800/80`}
          >
            <div onClick={() => setOpen(false)}>
              <a href="/">Accueil</a>
            </div>
            <div onClick={() => setOpen(false)}>
              <a href="#projets">Projets</a>
            </div>
            <div onClick={() => setOpen(false)}>
              <a href="#contact">Contact</a>
            </div>
            <div onClick={() => setOpen(false)}>
              <DarkMode />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
}

export default NavigationBar;
