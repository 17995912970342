import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGithub, faLinkedin,
  faXTwitter
} from '@fortawesome/free-brands-svg-icons';
import { motion } from 'framer-motion';

/**
 *
 * @param {*} props
 * @return {jsx}
 */
export default function Footer(props) {
  return (
    <footer className="bg-slate-200 dark:bg-neutral-700 bg-opacity-50 backdrop-blur-sm rounded-t-3xl md:rounded-3xl md:mb-10">
      <div className="flex justify-between p-10 w-5/6 md:w-1/2 m-auto">
        <div>Thomas d.</div>
        <div className="flex flex-col">
          <a className="" href="/">Accueil</a>
          <a className="" href="#projets">Projets</a>
          <a href="#contact">Contact</a>
        </div>
      </div>
      <div className="flex mx-auto w-fit gap-x-7">
        <motion.a
          whileHover={{ scale: 1.1, color: '#2dba4e' }}
          className="text-reset" href="https://github.com/ThomD7" target="_blank" rel="noreferrer">
          <FontAwesomeIcon size='xl' icon={faGithub} />
        </motion.a>
        <motion.a
          whileHover={{ scale: 1.1, color: '#0077b5' }}
          className="text-reset" href="https://www.linkedin.com/in/thomas-deschenes/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon size='xl' icon={faLinkedin} />
        </motion.a>
        <motion.a
          whileHover={{ scale: 1.1 }}
          className="text-reset" href="https://twitter.com/thomd77" target="_blank" rel="noreferrer">
          <FontAwesomeIcon size='xl' icon={faXTwitter} />
        </motion.a>
      </div>
      <div className="text-center py-5">
        © 2024 Thomas d.
      </div>
    </footer>
  );
}
