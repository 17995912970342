import './App.css';
import React from 'react';
import NavigationBar from './_misc/components/Navbar';
import Footer from './_misc/components/Footer';
import Homepage from './pages/HomePage';
import Projets from './pages/Projets';
import Contact from './pages/Contact';
import Top from './_misc/components/Top';

function App() {
  return (
    <div className='dark:bg-neutral-800 dark:text-white md:pb-5'>
      <div className="App md:w-2/3 md:mx-auto">
        <Top />
        <NavigationBar />
        <Homepage />
        <Projets />
        <Contact />
        <Footer />
      </div>
    </div>
  );
}

export default App;
