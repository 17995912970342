import React, { useState, useEffect } from "react";

export default function DarkMode() {
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        const savedTheme = localStorage.getItem("theme");
        if (savedTheme === "dark") {
            document.querySelector("html").classList.add("dark");
            setIsDarkMode(true);
        }
    }, []);

    const toggleDarkMode = () => {
        const htmlElement = document.querySelector("html");
        if (isDarkMode) {
            htmlElement.classList.remove("dark");
            localStorage.setItem("theme", "light");
        } else {
            htmlElement.classList.add("dark");
            localStorage.setItem("theme", "dark");
        }
        setIsDarkMode(!isDarkMode);
    };

    return (
        <div>
            <button
                className="text-2xl px-0 py-2 rounded-xl transition-colors duration-300 md:px-4"
                onClick={toggleDarkMode}
            >
                {isDarkMode ? <i className="fa-solid fa-lightbulb"></i> : <i className="fa-solid fa-moon"></i>}
            </button>
        </div>
    );
}
