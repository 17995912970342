import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGithub, faLinkedin,
    faXTwitter
} from '@fortawesome/free-brands-svg-icons';
import { motion } from 'framer-motion';

/**
 *
 * @return {jsx}
 */
export default function Contact() {
    return (
        <div className='h-[800px] m-auto mt-20'>
            <div className='text-center my-10 text-4xl' id="contact">Contact</div>
            <div>
                <div className="flex mx-auto w-fit gap-x-7 mb-10">
                    <motion.a
                        whileHover={{ scale: 1.1, color: '#2dba4e' }}
                        className="text-reset" href="https://github.com/ThomD7" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon size='2xl' icon={faGithub} />
                    </motion.a>
                    <motion.a
                        whileHover={{ scale: 1.1, color: '#0077b5' }}
                        className="text-reset" href="https://www.linkedin.com/in/thomas-deschenes/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon size='2xl' icon={faLinkedin} />
                    </motion.a>
                    <motion.a
                        whileHover={{ scale: 1.1 }}
                        className="text-reset" href="https://twitter.com/thomd77" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon size='2xl' icon={faXTwitter} />
                    </motion.a>
                </div>
                <div className="m-5 lg:w-1/2 mx-auto">
                    <motion.form
                        whileHover={{ y: -10 }}
                        className="border dark:border-neutral-700 rounded-3xl p-10 mx-5" action="https://formsubmit.co/612583629602069160e886af601abcfa" method="POST">
                        <input type="hidden" name="_template" value="table" />
                        <input type="text" name="_honey" style={{ display: 'none' }} />
                        <input type="hidden" name="_captcha" value="false" />
                        <input type="hidden" name="_next" value="https://www.thomasdeschenes.net/contact.html" />
                        <div className="flex flex-col">
                            <label for="firstName" className="form-label">Nom</label>
                            <input type="text" className="form-control  bg-slate-100 dark:bg-neutral-700 rounded-lg p-1 px-3 mb-4" name="First&nbsp;Name"
                                id="firstName" required />
                        </div>
                        <div className="flex flex-col">
                            <label for="email" className="form-label">E-mail</label>
                            <input type="email" className="form-control bg-slate-100 dark:bg-neutral-700 rounded-lg p-1 px-3 mb-4" name="E-mail"
                                id="email" required />
                        </div>
                        <div className='flex flex-col'>
                            <label for="comments" className="form-label">Message</label>
                            <textarea className="form-control  bg-slate-100 dark:bg-neutral-700 rounded-lg p-1 px-3 mb-4" id="comments" name="Comments"
                                rows="3" required=""></textarea>
                        </div>
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            className="bg-blue-300 dark:bg-blue-900 w-fit px-4 py-2 rounded-xl mt-5 cursor-pointer">
                            <button type="submit" className="btn btn-primary">Envoyer</button>
                        </motion.div>
                    </motion.form>
                </div>
            </div>
        </div>
    );
}

